
.main-div {
  display: flex;
  justify-content: center;
  width: 100%;
}

.left-div{
  width: 35%;
  padding: 10px;
  margin-left: 15px;
}

.right-div {
  width: 50%;
  justify-content: left;

  text-align: left;
}

/* Handheld */
@media screen and (max-width: 1500px) {

  .main-div {
    flex-direction: column;
  }

  .right-div {
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .left-div {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

a {
  text-decoration: none;
}

.selfie {
  display: flex;
  justify-content: center;

  padding: 30px;
  background-color: gray;
  border-radius: 5px;
  box-shadow: 5px 5px black;
}