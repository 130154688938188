
.blog-div {
    background-color:white;
    margin-inline: auto;
    width: 50%;
    max-width: 60em;

    padding: 50px;
    font-size: 1em;
    line-height:1.5em;
}

@media screen and (max-width: 992px){
    .blog-div {
        width:80%;
        margin: 0 auto 0 auto;
    }
}

.code-snip {
    padding: 4px;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    background-color: lightgray;
    border-radius: 5px;
    text-align: left;

}

/* SLP_Blog */
.blog-slp-function-img {
    height:2em;
    margin-top:.25em;
    margin-bottom:.25em;
    width: auto;
    max-width:100%;
}
