
/* Canvas */
.Canvas {
    border-style:solid;
    border-color: black;
    border-width: 2px;

    max-width: 100%;
    width: 300px;
    height: 300px;

}


/* Code Block */
.CodeBlockClass {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    font-size:.75em;
    line-height:1.25em;
    background-color:white;
    border-color:black;
    border-radius:5px;
    border-style: solid;
    border-width: 1px;

    padding:.5em;
    border-radius: 6px;
    overflow:scroll;

    box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.5);
}


/* ImageWithText Component */
.TextImageDiv {
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    width: 100%
}

.TextImage {
    padding-top: .5em;
    padding-bottom: .5em;

    max-width:100%;
}

.TextImageSpan {
    font-size: .75em;
    line-height: 1em;
    color:rgb(70, 70, 70);
    text-align:center;
}


/* Navbar Component */
.Navbar {
    width: 100%;
    height: 50px;
    background-color: #777D81;
}

.nav-button {
    border: none;
    height: 100%;
    width: 100px;
    margin-right: 10px;
    float: right;
    display: inline;
    padding: 8px;
    transition-duration: .5s;
    background-color: inherit;

    /* center vertically */
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.nav-button:hover {
    background-color: #ffffff;
}

  


/* Avatar Component */
.avatar-pic {
    border-radius: 50%;
    width: 100px;
  }

  .blog-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;

    width: 40%;
    height: 100px;

    background: #95a8e2;
    border-radius: 2px;
    box-shadow: 5px 5px black;
  }

  .blog-button:hover {

    background: #a7bcfa;
  }