
@font-face {
  font-family: 'Roboto-LightItalic';
  src: url('../Fonts/Roboto/Roboto-LightItalic.ttf');
}

@font-face {
  font-family: 'Roboto-Black-Italic';
  src: url('../Fonts/Roboto/Roboto-BlackItalic.ttf');
}

i {
  font-family:'Roboto-LightItalic', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

body {
  margin: 0;

  font-family:'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size:20px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
